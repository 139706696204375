import {SELECTORS as LC_SELECTORS} from "./levelContainer";

export const CSS_CLASSES = {
    'BLOCK': 'nav_navi-elem',
    'TAB_ELEMENT': 'nav_navi-elem__tab',
    'TILE_TITLE_ELEMENT': 'nav_navi-elem__tile-title',
    'TILE_IMAGE_ELEMENT': 'nav_navi-elem__tile-image',
    'LEVEL_1_MODIFIER': 'nav_navi-elem--level-1',
    'ACTIVE_MODIFER': 'nav_navi-elem--active'
};

export const SELECTORS = {
    'BLOCK': '.' + CSS_CLASSES.BLOCK,
    'TAB_ELEMENT': '.' + CSS_CLASSES.TAB_ELEMENT,
    'TILE_TITLE_ELEMENT': '.' + CSS_CLASSES.TILE_TITLE_ELEMENT,
    'TILE_IMAGE_ELEMENT': '.' + CSS_CLASSES.TILE_IMAGE_ELEMENT,
    'LEVEL_1_MODIFIER': '.' + CSS_CLASSES.LEVEL_1_MODIFIER,
    'ACTIVE_MODIFER': '.' + CSS_CLASSES.ACTIVE_MODIFER
};

export function makeActive(naviElem) {
    naviElem.classList.add(CSS_CLASSES.ACTIVE_MODIFER);
}

export function makeInactive(naviElem) {
    naviElem.classList.remove(CSS_CLASSES.ACTIVE_MODIFER);
}

export function createTabElement(naviElem) {
    if (!!naviElem.querySelector(SELECTORS.TAB_ELEMENT)) {
        return;
    }

    const tabSpan = document.createElement('span');
    tabSpan.classList.add(CSS_CLASSES.TAB_ELEMENT);
    tabSpan.textContent = naviElem.textContent;
    naviElem.appendChild(tabSpan);
}

export function isLevel1(naviElem) {
    return naviElem.classList.contains(CSS_CLASSES.LEVEL_1_MODIFIER);
}

export function getMenuAction(elem) {
    const closestEl = elem.closest('[data-nav-menu-action]');
    return {
        menuActionEl: closestEl,
        menuAction: closestEl?.getAttribute('data-nav-menu-action')
    };
}

export function getElementContext(naviElem) {
    return naviElem.getAttribute('data-nav-element-context') || "{}";
}

export function setMenuDisplayAction(naviElem, val) {
    naviElem.setAttribute('data-nav-menu-display-action', val);
}

export function getMenuDisplayAction(naviElem) {
    return naviElem.getAttribute('data-nav-menu-display-action');
}

export function getDownLink(naviElem) {
    return naviElem?.getAttribute('data-nav-down-link');
}

/**
 *
 *
 */
export function appendLevelContainer(naviElem, levelContainer) {
    naviElem.parentNode.appendChild(levelContainer);
}

export function hasLevelContainer(naviElem) {
    return !!naviElem.parentNode.querySelector(LC_SELECTORS.BLOCK);
}
