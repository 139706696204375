import {initSlider} from '@otto-ec/nav_patternsson/src/js/slider';
import {track} from "@otto-ec/nav_star-track/src/js/star-track";

function getPromoFeatureOrder(elem) {
    const ft3Div = elem.closest('[data-feature-order]');

    if (!!ft3Div) {
        try {
            return JSON.parse(ft3Div.getAttribute("data-feature-order"));
        } catch (e) {
            return null;
        }
    }
}

function getPromoFeatureTrackingLabels(elem) {
    const ft3Div = elem.closest('[data-feature-tracking-labels]');

    if (!!ft3Div) {
        try {
            return JSON.parse(ft3Div.getAttribute("data-feature-tracking-labels"));
        } catch (e) {
            return {};
        }
    }
}

function enrichFeature(feature, shortcutRoot, position, isPersonalised) {
    switch (feature.name) {
        case 'TopCategories-Context':
            const promoFeatureOrder = getPromoFeatureOrder(shortcutRoot);
            if (promoFeatureOrder) {
                feature.position = promoFeatureOrder;
                feature.labels = {
                    ...feature.labels,
                    ...getPromoFeatureTrackingLabels(shortcutRoot)
                };
            }
            feature.labels.nav_PersonalisedCategories = isPersonalised;
            return feature;

        case 'TopCategories-Item':
            feature.position = position;
            feature.labels.nav_FirstLevelPosition = position;
            return feature;

        default:
            return feature;
    }
}

function fetchWithTimeout(url, options={}, timeout=1000) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    let headers = (options && options.headers) ? options.headers : {};
    headers['X-Requested-With'] = 'XMLHttpRequest';
    options['headers'] = headers;
    return fetch(url, {
        ...options,
        signal: controller.signal
    }).then(response => {
        clearTimeout(id);
        return response.ok ? response.json() : [];
    });
}

function fetchCustomizedCategories(visitorId) {
    return fetchWithTimeout('/nav-customizer/top-category', {headers: {vid: visitorId}});
}

function getVisitorId() {
    const cookie = document.cookie;

    if (!cookie) return null;

    const cookieValue = cookie.split("; ").find(row => row.startsWith("visitorId"));
    return cookieValue ? cookieValue.split("=")[1] : null;
}

function findShortcutElement(shortcutRoot, psr) {
    return shortcutRoot.querySelector(`[data-nav-psr="${psr}"]`);
}

function reorderShortcutElements(shortcutRoot, newPsrOrder) {
    const shortcutElems = Array.from(
        shortcutRoot.querySelectorAll('[data-nav-psr]')
    );

    if (shortcutElems && shortcutElems.length > 0) {
        const parentElement = shortcutElems[0].parentElement;
        const sortedElems = newPsrOrder.map(psr => findShortcutElement(shortcutRoot, psr)).filter(e => e !== null);
        const unsortableElems = shortcutElems.filter(elem => !sortedElems.includes(elem));

        shortcutElems.forEach(elem => elem.remove());
        parentElement.append(...sortedElems, ...unsortableElems);
    }
}

function setPersonalizedShortcutMenuTitle(shortcutRoot) {
    const titleEl = shortcutRoot.querySelector('[data-nav-personalized-title]');
    if (!titleEl) return;
    titleEl.textContent = titleEl.dataset.navPersonalizedTitle;
}

export function findPosition(rootEl, id) {
    return Array.from(rootEl.querySelectorAll('.nav_upright-chip'))
                .findIndex(chip => chip.dataset.navItemFeatureId === id) +1;
}

let sliderScrolled = false;

export async function initShortcut(id, menuState) {
    const shortcutRoot = window.document.getElementById(id);
    if (!shortcutRoot) return;

    let isPersonalised = false;
    let customizedPsrOrder = [];

    try {
        const visitorId = getVisitorId();
        if (null != visitorId) {
            customizedPsrOrder = await fetchCustomizedCategories(visitorId);
        }
    } catch(e) {
    }

    if (customizedPsrOrder && customizedPsrOrder.length !== 0) {
        isPersonalised = true;
        setPersonalizedShortcutMenuTitle(shortcutRoot);
        reorderShortcutElements(shortcutRoot, customizedPsrOrder);
    }

    const sliderElement = shortcutRoot.querySelector(".nav_slider");
    if (!sliderElement) return;
    const slider = initSlider(sliderElement);
    shortcutRoot.classList.add('nav_shortcut-menu--visible');

    slider.registerScrollCallback(() => {
        if (!sliderScrolled) {
            track(shortcutRoot, "user-action/slider-scroll", commands => {
                return commands;
            });
            sliderScrolled = true;
        }
    });


    track(shortcutRoot, "user-action/view-shortcut-menu", commands => {
        commands[0].features = commands[0].features
            .map(feature =>
                enrichFeature(feature, shortcutRoot, findPosition(shortcutRoot, feature.id), isPersonalised))
            .sort((feature1, feature2) => {
                if (feature1.name === 'TopCategories-Item' && feature2.name === 'TopCategories-Item') {
                    return feature1.position - feature2.position;
                }
                return 0;
            });
        return commands;
    });

    const downLinkSelector = "[data-nav-down-link]";
    const children = shortcutRoot.querySelectorAll(downLinkSelector);

    Array.from(children).map(el => {
        el.addEventListener('click', event => {
            event.preventDefault();

            const el = event.target.closest(downLinkSelector);

            menuState["shortcutMenu"] = {
                isPersonalised: isPersonalised,
                position: findPosition(shortcutRoot, el.dataset.navItemFeatureId)
            };

            const downLink = el.dataset.navDownLink;
            window.o_global.eventQBus.emit('ftnav.global-navigation.open-layer', downLink);
            track(el, "user-action/open-menu-via-shortcut", commands => {
                let position = findPosition(shortcutRoot, el.dataset.navItemFeatureId);
                commands[0].dataContainer["nav_FirstLevelPosition"] = position;
                commands[0].dataContainer["nav_PersonalisedCategories"] = isPersonalised;
                commands[1].dataContainer.nav_MenuRank = position;
                commands[1].dataContainer["nav_FirstLevelPosition"] = menuState.shortcutMenu.position;
                commands[1].dataContainer["nav_PersonalisedCategories"] = menuState.shortcutMenu.isPersonalised;
                commands[1].dataContainer["nav_GlobalNavigationFeature"] = "top_category";
                commands[2].action.features.map(feature => enrichFeature(feature, shortcutRoot, position, isPersonalised));
                return commands;
            });
        });
    });
}
