import * as naviPanelDom from "./dom/naviPanel";
import {isLevel2} from "./dom/naviPanel";
import * as levelContainerDom from "./dom/levelContainer";
import * as menuHeaderDom from "./dom/menuHeader";
import {popLvl1ClickedRank} from "./mobileMenu";
import {track} from "@otto-ec/nav_star-track/src/js/star-track";

/**
 *
 *
 *
 *
 *
 */
export function navigateUp(backLink, navigationPath, menuState = {}) {
    const naviPanel = navigationPath.pop();
    const menuHeader = backLink.closest(menuHeaderDom.SELECTORS.BLOCK);

    if (!!naviPanel) {
        naviPanelDom.animateUp(naviPanel);
    }

    /*                                                                                  */
    /*                                   */
    const visibleLevelContainers = naviPanel.querySelectorAll(levelContainerDom.SELECTORS.VISIBLE_MODIFIER);
    for (let i = 0, n = visibleLevelContainers.length; i < n; i++) {
        levelContainerDom.makeInvisible(visibleLevelContainers[i]);
    }

    track(menuHeader, "user-action/menu-navigate-up", (commands) => {
        if (isLevel2(naviPanel)) {
            commands[0].dataContainer.nav_MenuRank = popLvl1ClickedRank();
        }
        if(menuState?.shortcutMenu !== undefined) {
            commands[0].dataContainer["nav_FirstLevelPosition"] = menuState.shortcutMenu.position;
            commands[0].dataContainer["nav_PersonalisedCategories"] = menuState.shortcutMenu.isPersonalised;
            commands[0].dataContainer["nav_GlobalNavigationFeature"] = "top_category";
        } else {
            commands[0].dataContainer["nav_GlobalNavigationFeature"] = "standard";
        }
        return commands;
    });
}
