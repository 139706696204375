import {initButton} from "./menuToggleButton";
import {init} from "./desktopMenu";
import {initMobile} from "./mobileMenu";
import {initShortcut} from "./shortcutMenu";
import {isMobileBreakpoint} from "./utils";
import {initSlider} from '@otto-ec/nav_patternsson/src/js/slider';
import {track} from "@otto-ec/nav_star-track/src/js/star-track";
import {registerMobile} from "./mobileElements";
import "@otto-ec/nav_patternsson/src/js/Tabs";

let menuState = {};

registerMobile();

/*                                                                                             */
/*                    */
if (isMobileBreakpoint()) {
    initMobile(menuState);
    initButton(menuState);

    window.o_global.eventQBus.on('ftnav.shortcut-menu.register', (id) => {
        initShortcut(id, menuState);
    });

} else {
    init(menuState);
}

window.o_global.eventQBus.on('ftnav.marken-slider.register', (id) => {
    const slider = document.querySelector(`#${id}`);
    initSlider(slider);
});

window.o_global.eventQBus.on('ftnav.brand-slider.register', (id) => {
    let sliderContainer = document.getElementById("brand-slider-" + id);
    let sliderEl = sliderContainer.getElementsByClassName("nav_slider")[0];
    let sliderScrolled = false;
    if (sliderEl) {
        const slider = initSlider(sliderEl);
        slider.registerScrollCallback(() => {
            if (!sliderScrolled) {
                track(sliderContainer, "user-action/slider-scroll");
                sliderScrolled = true;
            }
        });
    }
});
