export const CSS_CLASSES = {
    'BLOCK': 'nav_menu-header'
};

export const SELECTORS = {
    'BLOCK': '.' + CSS_CLASSES.BLOCK,
};

export function getMenuBackTitle(menuHeaderBackLink) {
    return menuHeaderBackLink.getAttribute('data-nav-menu-back-title');
}

export function getDownTrackingData(menuHeader) {
    const trackingData = menuHeader.getAttribute('data-nav-tracking-up');

    if (!!trackingData) {
        return JSON.parse(trackingData);
    } else {
        return {};
    }
}