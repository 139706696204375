export const CSS_CLASSES = {
    'BLOCK': 'nav_level-container',
    'BACKGROUND_MODIFIER': 'nav_level-container--background',
    'VISIBLE_MODIFIER': 'nav_level-container--visible',
    'LEVEL_2_MODIFIER': 'nav_level-container--level-2'
};

export const SELECTORS = {
    'BLOCK': '.' + CSS_CLASSES.BLOCK,
    'BACKGROUND_MODIFIER': '.' + CSS_CLASSES.BACKGROUND_MODIFIER,
    'VISIBLE_MODIFIER': '.' + CSS_CLASSES.VISIBLE_MODIFIER,
    'LEVEL_2_MODIFIER': '.' + CSS_CLASSES.LEVEL_2_MODIFIER
};

export function makeVisible(levelContainer) {
    levelContainer.classList.add(CSS_CLASSES.VISIBLE_MODIFIER);
}

export function makeInvisible(levelContainer) {
    levelContainer.classList.remove(CSS_CLASSES.VISIBLE_MODIFIER);
}

export function addBackground(levelContainer) {
    levelContainer.classList.add(CSS_CLASSES.BACKGROUND_MODIFIER);
}

export function removeBackground(levelContainer) {
    levelContainer.classList.remove(CSS_CLASSES.BACKGROUND_MODIFIER);
}

export function isLevel2Container(levelContainer) {
    return levelContainer.classList.contains(CSS_CLASSES.LEVEL_2_MODIFIER);
}