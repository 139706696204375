export const ID = 'nav_menu-toggle-button';

export const CSS_CLASSES = {
    'OPENED_MODIFIER': 'nav_menu-toggle-button--opened',
    'TITLE_OPEN_ELEMENT': 'nav_menu-toggle-button__title-open'
};

export const SELECTORS = {
    'ID': '#' + ID
};

const DATA_ATTRIBUTES = {
    'STATUS': 'data-menu-action-status',
    'INITIALIZED': 'data-nav-initialized'
}

export const STATUS = {
    'OPEN': 'open',
    'CLOSE': 'close'
}

export function setStatus(menuToggleButton, status) {
    menuToggleButton.setAttribute(DATA_ATTRIBUTES.STATUS, status);
}

function getStatus(menuToggleButton) {
    return menuToggleButton.getAttribute(DATA_ATTRIBUTES.STATUS);
}

export function isStatus(menuToggleButton, status) {
    return getStatus(menuToggleButton) === status;
}

export function markAsInitialized(menuToggleButton) {
    menuToggleButton.setAttribute(DATA_ATTRIBUTES.INITIALIZED, true);
}
