import {clearNavigationPath} from "./mobileMenu";
import * as menuToggleButtonDom from "./dom/menuToggleButton";
import {STATUS} from "./dom/menuToggleButton";
import * as menuDom from "./dom/menu";
import {calculateTopOffset, resetClasses} from "./utils";
import {trackOnNextPageImpression} from "@otto-ec/nav_star-track/src/js/star-track";

import {initSlider} from '@otto-ec/nav_patternsson/src/js/slider';
import {track} from "@otto-ec/nav_star-track/src/js/star-track";

let menuToggleButton;
let menu;

export function initButton(menuState) {
    menu = menuDom.getMenuRoot();
    if (!menu) return;
    menuToggleButton = document.getElementById(menuToggleButtonDom.ID);
    if (!menuToggleButton) return;

    let titleOpenElement = menuToggleButton.getElementsByClassName(menuToggleButtonDom.CSS_CLASSES.TITLE_OPEN_ELEMENT);
    if (titleOpenElement.length > 0) {
        titleOpenElement[0].setAttribute('style', '');
    }

    if (!!menuToggleButton) {
        /*                                                                                         */
        menuToggleButtonDom.setStatus(menuToggleButton, STATUS.CLOSE);

        menuToggleButton.addEventListener('click', event => {
            if (menuToggleButtonDom.isStatus(menuToggleButton, STATUS.CLOSE)) {
                window.o_global.eventQBus.emit('ftnav.global-navigation.open');
            } else {
                menuState.shortcutMenu = undefined;
                window.o_global.eventQBus.emit('ftnav.global-navigation.close',
                                               {'interaction': 'button'});
            }
        });

        window.o_global.eventQBus.on('ftnav.global-navigation.open', openMenu);
        window.o_global.eventQBus.on('ftnav.global-navigation.openSilent', openMenuSilent);
        window.o_global.eventQBus.on('ftnav.global-navigation.close', closeMenu);
        window.o_global.eventQBus.on('ftnav.global-navigation.closed', (opts) => {
            let action = (opts && opts.interaction === 'button') ? 'close' : 'exit';
            if (action === 'close') {
                track(menuToggleButton, "user-action/menu-close");
            } else if (action === 'exit') {
                track(menuToggleButton, "user-action/menu-exit");
            }
        });

        menuToggleButtonDom.markAsInitialized(menuToggleButton);
    }
}

function openMenuSilent () {
    menu = menuDom.getMenuRoot();
    menuToggleButton = document.getElementById(menuToggleButtonDom.ID);
    if (menuToggleButtonDom.isStatus(menuToggleButton, STATUS.CLOSE)) {
        menuToggleButtonDom.setStatus(menuToggleButton, STATUS.OPEN);
        menuToggleButton.classList.toggle(menuToggleButtonDom.CSS_CLASSES.OPENED_MODIFIER);

        if (!!menu) {
            updateMenuVisibility(menu, STATUS.OPEN);
            menu.style.setProperty('--nav-menu-top-offset', calculateTopOffset(menu));
        }

        /*         */
        window.o_global.eventQBus.emit('ftnav.navigation.open');
        /*              */
        window.o_global.eventQBus.emit('ftnav.global-navigation.opened');
    }
}

function openMenu () {
    menu = menuDom.getMenuRoot();
    menuToggleButton = document.getElementById(menuToggleButtonDom.ID);
    if (menuToggleButtonDom.isStatus(menuToggleButton, STATUS.CLOSE)) {
        menuToggleButtonDom.setStatus(menuToggleButton, STATUS.OPEN);
        menuToggleButton.classList.toggle(menuToggleButtonDom.CSS_CLASSES.OPENED_MODIFIER);

        if (!!menu) {
            updateMenuVisibility(menu, STATUS.OPEN);
            menu.style.setProperty('--nav-menu-top-offset', calculateTopOffset(menu));
        }

        track(menuToggleButton, "user-action/menu-open");

        /*                                                               */
        /*                                                */
        /*                          */
        /*                                               */
        /*  */

        /*         */
        window.o_global.eventQBus.emit('ftnav.navigation.open');
        /*              */
        window.o_global.eventQBus.emit('ftnav.global-navigation.opened');
    }
}

function closeMenu (opts) {
    menu = menuDom.getMenuRoot();
    menuToggleButton = document.getElementById(menuToggleButtonDom.ID);
    if (menuToggleButtonDom.isStatus(menuToggleButton, STATUS.OPEN)) {
        clearNavigationPath();
        menuToggleButtonDom.setStatus(menuToggleButton, STATUS.CLOSE);
        menuToggleButton.classList.toggle(menuToggleButtonDom.CSS_CLASSES.OPENED_MODIFIER);

        /*         */
        window.o_global.eventQBus.emit('ftnav.navigation.close');
        /*              */
        window.o_global.eventQBus.emit('ftnav.global-navigation.closed', opts);

        if (!!menu) {
            updateMenuVisibility(menu, STATUS.CLOSE);
            resetClasses();
        }
    }
}

export function updateMenuVisibility(menuContainer, status) {
    if (status === STATUS.OPEN) {
        menuContainer.classList.remove('nav_menu--invisible');
        document.body.classList.add('nav__body--scrolling-disabled');
    } else {
        document.body.classList.remove('nav__body--scrolling-disabled');
        menuContainer.classList.add('nav_menu--invisible');
    }
}
