export const CSS_CLASSES = {
    'BLOCK': 'nav_navi-panel',
    'DOWN_ANIMATION_MODIFIER': 'nav_navi-panel--down-animation',
    'LEVEL_1_PANEL_MODIFIER': 'nav_navi-panel--level-1',
    'LEVEL_2_PANEL_MODIFIER': 'nav_navi-panel--level-2',
};

export const SELECTORS = {
    'BLOCK': '.' + CSS_CLASSES.BLOCK,
    'DOWN_ANIMATION_MODIFIER': '.' + CSS_CLASSES.DOWN_ANIMATION_MODIFIER
};

export function animateDown(naviPanel) {
    naviPanel.classList.add(CSS_CLASSES.DOWN_ANIMATION_MODIFIER);
}

export function animateUp(naviPanel) {
    naviPanel.classList.remove(CSS_CLASSES.DOWN_ANIMATION_MODIFIER);
}

export function getTrackingData(naviPanel) {
    const trackingData = naviPanel.getAttribute('data-nav-tracking-data');

    if (!!trackingData) {
        return JSON.parse(trackingData);
    } else {
        return {};
    }
}

export function isLevel2(naviPanel) {
    return naviPanel.classList.contains(CSS_CLASSES.LEVEL_1_PANEL_MODIFIER);
}

export function isInLevel2(elem) {
    const closestNaviPanel = elem.closest(SELECTORS.BLOCK);
    return closestNaviPanel && closestNaviPanel.classList.contains(CSS_CLASSES.LEVEL_2_PANEL_MODIFIER);
}
